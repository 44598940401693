<style lang="less" scoped>
.van-cell-self {
  border-bottom: 1px solid #dddddd;
}

.top-info {
  margin: 0px 0 20px 0;

  .body {
    border-top: 1px solid #8b8a8a;
    border-bottom: 1px solid #8b8a8a;
    padding: 5px 0;
  }
  .unit{
    font-size: 0.7rem;
  }

  .row {
    display: flex;
    padding: 5px;

    .row-item {
      // margin: 5px 10px;
      width: 49%;
      font-size: 0.8rem;
      text-align: left;
    }
  }

  .row-title{
    font-size: 16px;
  }
}

.body-info {
  .row {
    display: flex;
    line-height: 2rem;
    .title {
      max-width: 280px;
    }

    .row-item {
      margin-left: 8px;
    }

    .mid {
      flex: 1;
    }
  }

  .list-item {
    margin: 10px;
    border-left-width: 6px;
    border-left-style: solid;
    border-radius: 5px;
    font-size: 0.9rem;
    background-color: #333;
    padding-bottom: 5px;

    .profit-rate {
      padding: 0px 10px;
      box-sizing: border-box;
      margin-left: 10px;
    }

    .cur-income {
      padding: 0px 10px;
      box-sizing: border-box;
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <div style='position: relative;min-height: 880px;'>
    <div>
      <van-search
          clearable
          left-icon=""
          input-align="center"
          placeholder="请输入策略名称/全拼/字母"
          input-class="search-input"
          v-model="search"
      />

      <div class="top-info">
        <!--      <div class="row title">-->

        <!--      </div>-->
        <div class="row">
          <div class="row-item">
            账号名称： <span class="row-title">{{ info.account_name }}</span>
          </div>
          <div class="row-item">资产估值: {{ getFixed(info.asset_valuation,4) }}</div>
        </div>
        <div class="row">
          <div class="row-item">权益: {{ getFixed(info.cur_rights, 2) }}</div>
          <div class="row-item" :class="{ green: info.cur_rights_rate > 0, red: info.cur_rights_rate < 0 }">收益率: {{ getPrecent(info.cur_rights_rate, 2) }}</div>
        </div>

        <div class="body">

          <div class="row">
            <span class="row-title">{{info.currency_name}}</span>
          </div>

          <div class="row value">
            <div class="row-item">当前价格: {{ getFixed(info.cur_price, 2) }}</div>
            <div class="row-item">保证金率: {{ getPrecent(info.margin_rate, 2) }}</div>
          </div>



          <div class="row">
            <div class="row-item" :class="{ green: info.cur_profi > 0, red: info.cur_profi < 0 }">
              收益: {{ getFixed(info.cur_profi, 2) }} <span class="unit"> {{ info.margin_type }}</span>
            </div>
            <div class="row-item" :class="{ green: info.cur_profi > 0, red: info.cur_profi < 0 }">
              收益率:{{ getPrecent(info.cur_profi_rate, 2) }}
            </div>
          </div>

          <div class="row value">
            <div class="row-item" :class="{ green: info.all_profi > 0, red: info.all_profi < 0 }">累计收益: {{ getFixed(info.all_profi, 2) }}  <span class="unit"> {{ info.margin_type }}</span></div>
            <div class="row-item">综合杠杆: {{ getFixed(info.avg_leverage, 2) }}</div>
          </div>
        </div>
      </div>

      <div class="body-info">
        <div class="list">
          <div
              v-for="item in show_table_list"
              v-bind:key="item.id"
              class="list-item"
              :style="{
            borderLeftColor: getBorderColor(item),
          }"
          >
            <div class="row ">
              <div class="row-item title">
                {{ item.name }}
              </div>
              <div class="mid"></div>
              <div v-if="!item.has_position" class="row-item">
                <van-button @click="handlerSetting(item)" type="info" size="small">配置</van-button>
              </div>
            </div>
            <template v-if="item.has_position">
              <div class="row">
                <div class="row-item">开仓时间: {{ item.open_time }}</div>
                <div class="profit-rate" :style="{ backgroundColor: getColor(item.profit_rate)}">
                  {{ getPrecent(item.profit_rate,2) }}
                </div>
              </div>

              <div class="row">
                <div class="row-item">开仓均价: {{ item.open_price }}</div>
                <div class="row-item cur-income" :style="{ color: getColor(item.cur_income)}">
                  当前收益: {{ getFixed(item.cur_income,6) }}
                </div>
              </div>

              <div class="row">
                <div class="row-item">杠杆倍数: {{ item.ganggan_times }}</div>
                <div class="row-item">风控策略: {{ item.risk_strategy }}</div>
              </div>
              <div class="row">
                <div class="row-item">开仓方向: {{ item.side }}</div>
                <div class="row-item">持仓数量: {{ item.position_number }}</div>
              </div>

              <div class="row">
                <div class="row-item">触发价格: {{ item.price_list.length?getFixed(item.price_list[0],4) :'-'}}</div>
                <div class="row-item">触发数量: {{ item.trigger_num }}</div>
              </div>

              <div class="row">
                <div class="row-item">
                  <van-field
                      class="van-cell-self"
                      size="small"
                      type="number"
                      v-model="item.pincan_num"
                      :placeholder="`请输入：平仓数量< = ${item.position_number}`"
                  />
                </div>
                <div class="mid"></div>
                <div class="row-item">
                  <van-button @click="handlerPincan(item)" size="small">平仓</van-button>
                </div>
                <div class="row-item">
                  <van-button @click="handlerSetting(item)" type="info" size="small" >配置</van-button>
                </div>
              </div>
            </template>
            <template v-else></template>
          </div>
        </div>
      </div>
    </div>
    <VersionNum style="position:absolute;left:0;bottom:0;"></VersionNum>
  </div>

</template>
<script>

import {getHoldingsDetail, getStrategyInfos, pincan} from "../../api/home.js";
import {Notify, Dialog} from "vant";
import InfoCommen from "../../mixins/InfoCommen";
import VersionNum from "../com/version_num";
let timer = null
export default {
  name: "PostionInfo",
  components:{
    VersionNum
  },
  mixins: [InfoCommen],
  data() {
    return {
      search: '',
      list: [],
      info: {
        account_id: "",
      },
      account_id: "",
      currency_name: "",
    };
  },
  computed: {
    show_table_list() {
      if (this.search) {
        return this.list.filter(item => {
          const title = String(item.name).toLowerCase()
          return title.includes(this.search.toLowerCase())

        })
      } else {
        return this.list
      }
    }
  },
  mounted() {
    // this.handlerSetting();
    console.log(this.$route.query.account_id)
    this.handlerGetHoldingsDetail();
    this.handlerGetList();
    timer = setInterval(() => {
      this.handlerGetHoldingsDetail();
      this.handlerGetList();
    },10000)
  },
  destroyed() {
    if(timer){
      clearInterval(timer)
    }
  },

  methods: {
    //跳转配置页面
    handlerSetting(row) {
      this.$router.push("/home/postion_set?strategy_id=" + row.strategy_id);
    },
    //获取账号、交易币对数据
    handlerGetHoldingsDetail() {
      // getHoldingsDetail()
      getHoldingsDetail(this.$route.query).then((res) => {
        const {account_info, currency_pos_info} = res.data;
        //权益=初始权益+累计权益
        const cur_rights = account_info.init_asset + account_info.cumulative_revenue_actual;
        this.info = {
          //资产评估
          asset_valuation: account_info.asset_valuation,
          //交易账号
          account_name: account_info.ac_nick_name,
          //权益
          cur_rights,
          //收益率=累计收益/初始收益
          cur_rights_rate: account_info.asset_valuation? (account_info.cumulative_revenue_actual / account_info.init_asset ) :0,
          cur_price: currency_pos_info.price,
          cur_profi: currency_pos_info.earning_revenue,
          cur_profi_rate: currency_pos_info.currency_equity ? currency_pos_info.earning_revenue / currency_pos_info.currency_equity : 0,
          margin_rate: currency_pos_info.margin_rate,
          all_profi: currency_pos_info.cumulative_revenue_actual,
          margin_type: currency_pos_info.margin_type,
          avg_leverage: currency_pos_info.avg_leverage,
          currency_name: currency_pos_info.currency_name

        };
      });
    },
    //获取策略数据
    handlerGetList() {
      getStrategyInfos(this.$route.query).then((res) => {
        const {had_position = [], no_position = []} = res.data;
        this.list = [...had_position, ...no_position].map((item) => {
          let side = item.position_side
          if (item.currency_type === "币本位"){
            if (item.position_side === "SHORT"){
              if (item.position_state === "套保"){
                side = "套保"
              }
            }
          }
          return {
            name: item.strategy_name,
            open_time: item.open_time || "-",
            profit_rate: item.earning_rate || 0,
            open_price: item.position_avg_price || "-",
            ganggan_times: item.strategy_leverage || "-",
            cur_income: item.earning_revenue || "-",
            risk_strategy: item.risk_type || "-",
            trigger_price: item.risk_trigger_price || "-",
            trigger_num: item.risk_quantity || "-",
            pincan_num: "",
            pincan_loading: false,
            strategy_id: item.strategy_id,
            has_position: item.position_number !== undefined,
            position_number: item.position_number,
            position_side: item.position_side,
            position_state: item.position_state,
            margin_coin: item.margin_coin,
            side:side,
            price_list:item.risk_flag?item.price_list:[]
          };
        });
      });
    },
    //平仓
    handlerPincan(item) {
      if (item.pincan_loading) {
        return;
      }
      const num = Number(item.pincan_num || item.position_number);
      Dialog.confirm({title: '提示', message: `是否平仓,平仓数量${num}`}).then(() => {
        item.pincan_loading = true;
        pincan(item.strategy_id, num)
            .then((res) => {
              Notify({type: "success", message: res.message});
              this.handlerGetList();
              item.pincan_loading = false;
            })
            .catch(() => {
              item.pincan_loading = false;
            });
      })

    },
    //设置颜色
    getBorderColor(item) {
      console.log(item.has_position)
      if (item.has_position) {
        if (item.position_side === "SHORT") {
          if (item.currency_type === "U本位"){
            return '#e45353'
          }else{
            if (item.position_state === '套保') {
              return 'yellow'
            } else {
              return '#e45353'
            }
          }
        } else if (item.position_side === 'LONG') {
          return '#4bba41'
        }
      } else {
        return 'gray'
      }
    },

  },

  beforeRouteUpdate(to, from, next){
    console.log(to,from)
    console.log(this.$route.query.account_id)
    if(to.path=== "/home"){
      next(vm=>{
        vm.newPath = this.$route.query.account_id
      })
    }
  }
};
</script>
